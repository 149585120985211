import { setUserLogin } from "../login";
import { removeAllLocalStorage } from "../../../utils/helpers";
/* eslint-disable space-before-function-paren */
export const SET_LOADING = "errorGeneral/SET_LOADING";
export const CLEAR_ERROR = "errorGeneral/CLEAR_ERROR";
export const SET_ERROR = "errorGeneral/SET_ERROR";
export const SET_ERROR_404 = "errorGeneral/SET_ERROR_404";
export const SET_ERROR_400 = "errorGeneral/SET_ERROR_400";
export const SET_SUCCESS_200 = "errorGeneral/SET_SUCCESS_200";

export const INIT_DATA = "errorGeneral/INIT_DATA";
export const SET_DOUBLE_SUBMIT = "errorGeneral/SET_DOUBLE_SUBMIT";

export const setLoading = (payload) => ({
  type: SET_LOADING,
  payload,
});

export const setDoubleSubmit = (payload) => ({
  type: SET_DOUBLE_SUBMIT,
  payload,
});

export const setError = (payload) => ({
  type: SET_ERROR,
  payload,
});

export const setError400 = (payload) => ({
  type: SET_ERROR_400,
  payload,
});

export const setSuccess200 = (payload) => ({
  type: SET_SUCCESS_200,
  payload,
});

export const setError404 = (payload) => ({
  type: SET_ERROR_404,
  payload,
});

export const setClearError = () => ({
  type: CLEAR_ERROR,
});

export const handleErrorBE =
  (response = { status: 400, data: {} }) =>
  (dispatch, getState) => {
    const { status, data } = response;

    if (status === 401) {
      if(getState().auth.isAuth) {
        dispatch(setUserLogin({}));
        removeAllLocalStorage();
        
        window.location.href = "/login";
      }
    }

    switch (data.code) {
      case 400:
        dispatch(
          setError400({
            isError400: true,
            message: data?.message,
            code: data?.code,
          })
        );
        return;

      case 404:
        dispatch(
          setError404({
            isError404: true,
            message: data?.message,
            code: data?.code,
          })
        );
        return;

      default:
        dispatch(
          setError({
            isError: true,
            message: data?.message,
            code: data?.code,
          })
        );
        return;
    }
  };

export const handleError = (error) => (dispatch, getState) => {
  if (error?.code === 401) {
    if(getState().auth.isAuth) {
      dispatch(setUserLogin({}));
      removeAllLocalStorage();
      
      window.location.href = "/login";
    }
  }
  dispatch(
    setError({
      isError: true,
      message: error?.message,
      code: error?.code,
    })
  );
};

export const handleErrorCustom = (message, code = 400) => (dispatch, getState) => {
  if (code === 401) {
    if(getState().auth.isAuth) {
      dispatch(setUserLogin({}));
      removeAllLocalStorage();
      
      window.location.href = "/login";
    }
  }
  dispatch(
    setError({
      isError: true,
      message: message,
      code: code,
    })
  );
};


export const handleSuccess = (res = null, customRes = null) => (dispatch) => {
  if(res) {
    dispatch(
      setSuccess200({
        isSuccess: true,
        message: res?.data?.message,
        code: res.code,
      })
    );
  } else if(customRes) {
    dispatch(
      setSuccess200({
        title: customRes.title,
        isSuccess: true,
        message: customRes.message,
        code: customRes.code,
        title: customRes.title ?? null
      })
    )
  }
};
export const clearError = () => (dispatch) => {
  dispatch(
    setClearError({
      isError: false,
      message: "",
      code: "",
    })
  );
};

export const clearSucces200 = () => (dispatch, state) => {
  dispatch(
    setSuccess200({
      ...state.success,
      isSuccess: false,
    })
  );
};

export const clearError400 = () => (dispatch) => {
  dispatch(
    setError400({
      isError400: false,
      message: "",
      code: "",
    })
  );
};

export const clearError404 = () => (dispatch) => {
  dispatch(
    setError404({
      isError404: false,
      message: "",
      code: "",
    })
  );
};
