import { makeStyles } from "@material-ui/core";
import moment from "moment";
import { useSelector } from "react-redux";

import CalendarIcon from "../../../../../../assets/icons/colored/calendar-icon.svg";
import ClockIcon from "../../../../../../assets/icons/colored/clock-icon.svg";
import AirplaneIcon from "../../../../../../assets/icons/colored/airplane-icon.svg";
import Airplane2Icon from "../../../../../../assets/icons/colored/airplane-2-icon.svg";
import BelumBerangkat from "../../../../../../assets/icons/colored/belum-berangkat.svg";
import SedangBerangkat from "../../../../../../assets/icons/colored/sedang-berangkat.svg";
import SudahBerangkat from "../../../../../../assets/icons/colored/sudah-berangkat.svg";
import CategoryIcon from "../../../../../../assets/icons/colored/category-icon.svg";
import TicketingIcon from "../../../../../../assets/icons/colored/ticketing-icon.svg";
import TotalSeatIcon from "../../../../../../assets/icons/colored/total-seat-icon.svg";
import FilledSeatIcon from "../../../../../../assets/icons/colored/filled-seat-icon.svg";
import AvailableSeatIcon from "../../../../../../assets/icons/colored/available-seat-icon.svg";
import IconSeatTersisaMaroon from 'assets/icons/seat-tersisa-maroon.svg'
import GarudaIndonesiaLogo from "../../../../../../assets/images/garuda-indonesia-logo.png";
import CodeBandaraMaroon from 'assets/icons/kode-bandara-maroon.svg'

import { IDRFormater, handleName } from "utils/helpers";
import Badge from "components/badge";
import { useEffect, useState } from "react";
// import SudahBerangkat from "assets/icons/colored/sudah-berangkat.svg";

const useStyle = makeStyles({
  paketCard: {
    background: "#FBF3F2",
    borderRadius: "40px",
    cursor: "pointer",
    fontSize: "General Sans, sans-serif",
    padding: "51px 12px 12px 12px",
    position: "relative",
    zIndex: 1,
    "&:nth-child(1)": {
      borderRadius: "0px 0px 40px 40px",
    },
    "& .card_content": {
      background: "#fff",
      borderRadius: "32px",
      boxShadow:
        "0px 10px 16px -6px rgba(0, 0, 0, 0.04), 0px 4px 80px -4px rgba(0, 0, 0, 0.04)",
      display: "flex",
      padding: "10px 10px 12px 12px",
    },
    "& .card_image_wrapper": {
      borderRadius: "16px",
      boxShadow: "0px 6px 64px -4px rgba(0, 0, 0, 0.04)",
      filter:
        "drop-shadow(0px 10px 16px rgba(0, 0, 0, 0.04)) drop-shadow(0px 6px 32px rgba(0, 0, 0, 0.04))",
      overflow: "hidden",
      position: "relative",
      marginTop: "-38px",
      "& .card_image": {
        height: "248px",
        objectFit: "cover",
        width: "200px !important",
      },
    },
    "& .card_text_wrapper": {
      paddingLeft: "12px",
      width: "100%",
      "& .title": {
        // display: '-webkit-box',
        // '-webkit-box-orient': 'vertical',
        // overflow: 'hidden',
        // '-webkit-line-clamp': '3',
      },
      "& .card_text_title": {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        borderBottom: "1px dashed #BDBDBD",
        borderSpacing: "5px",
        paddingBottom: "8px",
        position: "relative",

        "& .title_card": {
          fontWeight: 600,
          fontSize: "14px",
          textTransform: "uppercase",
          lineHeight: "20px",
          width: "199px !important",
          position: "absolute",
          left: 0,
          top: 0,
          overflow: "hidden",
          backgroundColor: "red",
        },
        "& .card_badge": {
          background: "#2eb24333",
          borderRadius: "4px",
          color: "#333D29",
          fontSize: "8px",
          fontWeight: "600",
          padding: "4px 8px",
          position: "absolute",
          top: "0px",
          right: "2px",
          width: "fit-content",
        },
      },
      "& .card_detail_item": {
        display: "flex",
        gap: "8px",
        alignItems: "center",
        marginTop: "12px",
        fontSize: "12px",
        "& .green_bold_text": {
          background:
            "linear-gradient(204.44deg, #01F91A -18.49%, #359B05 84.72%)",
          fontWeight: 700,
          "-webkit-background-clip": "text",
          "-webkit-text-fill-color": "transparent",
        },
      },
    },
  },
});

const titleStyle = {
  display: '-webkit-box',
  WebkitBoxOrient: 'vertical',
  overflow: 'hidden',
  WebkitLineClamp: 3,
  /* Properti CSS lainnya jika diperlukan */
};

const PaketCard = ({ data }) => {
  const classes = useStyle();

  const auth = useSelector(({ auth }) => auth)

  const dateFormatter = (val) => {
    return moment(val).format("DD MMMM YYYY");
  };

  const [agents, setAgents] = useState([]);
  const [agentsRemaining, setAgentsRemaining] = useState(0);
  const [branches, setBranches] = useState([]);
  const [branchesRemaining, setBranchesRemaining] = useState(0);

  useEffect(() => {
    const agentData = data?.publish_settings?.agents
    const branchData = data?.publish_settings?.branches
  
    if(agentData?.length > 0) {
      setBranchesRemaining(branchData.length - 1)
      setBranches(branchData.splice(0, 1))

      setAgentsRemaining(agentData.length - 2)
      setAgents(agentData.splice(0, 1))

    } else {

      setBranchesRemaining(branchData.length - 2)
      setBranches(branchData.slice(0, 2))

      setAgentsRemaining(agentData.length - 2)
      setAgents(agentData.slice(0, 2))
    }

  }, [data])

  return (
    <>
      <div className="relative flex my-0 mx-auto h-full w-full">
        <div className="card_content relative !z-20">
          <div className="card_image_wrapper z-20">
            <img
              src={data.image_thumbnail}
              alt="card image"
              className="card_image"
            />
            <div className="absolute bottom-0 w-full">
              <div className="bg-white rounded-t-lg w-fit p-2">
                {!data.status_keberangkatan && (
                  <img src={BelumBerangkat} alt="icon" width={16} height={16} />
                )}
                {data.status_keberangkatan == 1 && (
                  <img src={SedangBerangkat} alt="icon" width={16} height={16} />
                )}
                {data.status_keberangkatan === 2 && (
                  <img src={SudahBerangkat} alt="icon" width={16} height={16} />
                )}
              </div>
              <div className="backdrop-blur bg-black/25 px-3 py-1 w-full">
                <div className="flex gap-2 items-center text-white text-xs">
                  <img src={CalendarIcon} alt="icon" width={16} height={16} />
                  {data.thn_masehi} M/{data.thn_hijriyah} H
                </div>
                <div className="flex gap-2 items-center text-white text-xs mt-2">
                  <img src={CategoryIcon} alt="icon" width={16} height={16} />
                  {data?.kategori}
                </div>
              </div>
              <div className="bg-white p-1">
                <div className="flex justify-between w-full px-1 pb-1">
                  <div className="flex gap-1 text-xs font-semibold black-linear-text">
                    <img src={TotalSeatIcon} alt="icon" />
                    {data.kuota ? data.kuota : '-'}
                  </div>
                  <div className="flex gap-1 text-xs blue-linear-text font-semibold">
                    <img src={FilledSeatIcon} alt="icon" />
                    {data.jumlah_jamaah ? data.jumlah_jamaah : '-'}
                  </div>
                  <div className={`flex gap-1 ${data.kuota_tersedia > 5 ? 'green-linear-text' : 'red-linear-text'} text-xs font-semibold`}>
                    {data.kuota_tersedia > 5 ? (
                      <>
                        <img src={AvailableSeatIcon} alt="icon" />
                      </>
                    ) : (
                      <>
                        <img src={IconSeatTersisaMaroon} alt="icon" />
                      </>
                    )}
                    {data.kuota_tersedia ? data.kuota_tersedia : '-'}
                  </div>
                </div>
                {data?.jamaah_without_ticket > 0 && (
                  <div className="bg-[#92E6A7] p-1 text-[#1A7431] flex justify-center items-center text-xs font-semibold rounded-b-xl">
                    {data?.jamaah_without_ticket} pax Tanpa Tiket
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="card_text_wrapper">
            <div className="flex flex-row pb-2 justify-start items-center w-full border-dashed border-[#BDBDBD] relative border-b-2">
              <div className="title text-[14px] font-semibold text-[#141414] w-[55%]" style={titleStyle}>
                {data.judul_paket}
              </div>
              {data?.status_keberangkatan === 0 ? (
                <>
                  {data.status === 0 ? (
                    <div
                          className="flex flex-row justify-center py-[4px] px-[8px] rounded-md !bg-[#56459233] text-[#564592] font-semibold text-[8px] absolute  right-2 top-0 ml-[16px]"
                        >
                      Draft
                    </div>
                  ) : (
                    <div
                      className="flex flex-row justify-center py-[4px] px-[8px] rounded-md !bg-[#2eb24333] text-[#333D29] font-semibold text-[8px] absolute  right-2 top-0 ml-[16px]"
                    >
                      Publish
                    </div>
                  )}
                </>
              ) : (
                <>
                  {data.status_keberangkatan === 1 ? (
                    <div
                      className="flex flex-row justify-center py-[4px] px-[8px] rounded-md !bg-[#FFF2B2] !text-[#e5be00] font-semibold text-[8px] absolute  right-2 top-0 ml-[16px]"
                    >
                      Sedang Berangkat
                    </div>
                  ) : (
                    <div
                      className="flex flex-row justify-center py-[4px] px-[8px] rounded-md !bg-[#CED4DA] !text-[#6C757D] font-semibold text-[8px] absolute  right-2 top-0 ml-[16px]"
                    >
                      Sudah Berangkat
                    </div>
                  )}
                </>
              )}
            </div>
            <div className="card_detail_item">
              <img src={CalendarIcon} alt="icon" width={16} height={16} />
              {dateFormatter(data.jadwal_keberangkatan)}
            </div>
            <div className="card_detail_item">
              <img src={ClockIcon} alt="icon" width={16} height={16} />
              {data.jumlah_hari} Hari
            </div>
            <div className="card_detail_item">
              <img src={AirplaneIcon} alt="icon" width={16} height={16} />
              <p className="title-paket">{data?.maskapai?.name}</p>
            </div>
            <div className="card_detail_item">
              <img src={SudahBerangkat} alt="icon" width={16} height={16} />
              <p className="title-paket">Landing {data?.mendarat_di ? data?.mendarat_di : '-'}</p>
            </div>
            <div className="card_detail_item">
              <img src={CodeBandaraMaroon} alt="icon" width={16} height={16} />
              <p className="title-paket">Via {data?.terbang_dari ? data?.terbang_dari : '-'}</p>
            </div>
            <div className="card_detail_item">
              <img src={TicketingIcon} alt="icon" width={16} height={16} />
              <span className="text-xs">
                <span className="green_bold_text text-base">
                  {IDRFormater(data.price_start)}
                </span>
                /pax
              </span>
            </div>
          </div>
        </div>
        {(!data?.publish_settings?.public && auth.user.access_data === 'all_branches') && (
          <div className="absolute top-[-32px] bg-[#FAFAFA] w-full right-0 !z-10 h-[70px] rounded-t-[32px] pt-[5px]">
              <div className="pl-5 flex gap-1">
                {branches?.map((item, index) => (
                  <div
                    key={index}
                    style={{ backgroundColor: item.color, color: item.color_text }}
                    className={`py-[4px] px-[8px] rounded-md font-semibold text-[8px] max-w-[65px] !leading-[15px] line-clamp-1`}
                  >
                    <div className="line-clamp-1">
                      {item.name.toUpperCase()}
                    </div>
                  </div>
                ))}
                {!!branchesRemaining > 0 && (
                  <div
                    style={{ backgroundColor: "#E0E0E0", color: "#141414" }}
                    className={`py-[4px] px-[8px] rounded-md font-semibold text-[8px] !leading-[15px]`}
                  >
                    +{branchesRemaining}
                  </div>
                )}

                {agents?.map((item, index) => (
                  <div
                    key={index}
                    style={{ backgroundColor: "#CCDCFF", color: "#60308C" }}
                    className={`py-[4px] px-[8px] rounded-md font-semibold text-[8px]`}
                  >
                    {handleName(item.title, item.name)}
                  </div>
                ))}
                {agentsRemaining > 0 && (
                  <div
                    style={{ backgroundColor: "#E0E0E0", color: "#141414" }}
                    className={`flex flex-row justify-center py-[4px] px-[8px] rounded-md font-semibold text-[8px] !leading-[15px]`}
                  >
                    +{agentsRemaining}
                  </div>
                )}
              </div>
          </div>
        )}
      </div>
    </>
  );
};

export default PaketCard;
