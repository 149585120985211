import "./index.css";
// import "../travel.scss";
import { useEffect } from "react";
import { Layout, theme } from "antd";
import React, { useCallback } from "react";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core";
import Sidebar from "../components/template_layout/Siderbar";
import { menuSidebarList } from "../configuration";
import ScrollCustom from "../components/scroll";
import HeaderLayout from "../components/template_layout/Header/Dropdown";
import { pathnameCONFIG } from "../configuration/pathConfig";

import { useDispatch, useSelector } from "react-redux";
import UnauthorizedPage from "pages/unauthorized";


const { Content } = Layout;

const useStyles = makeStyles({
  menu: {
    backgroundColor: "#fff !important",
    width: "200px !important",
    height: "100%",
    color: "#000",
  },
  sidebar: {
    width: "100px !important",
    height: "1100px",
    background: "red !important",
  },
  layout: {
    height: "100vh",
    display: "flex",
    flexDirection: "row",
    overflowX: "hidden",
  },
  content: {
    padding: "117px 16px 16px 108px!important",
    width: '100%',
  },
});

const Container = ({ children, secondContainer }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const history = useHistory();

  const { error, error400, error404, success } = useSelector(
    (state) => state.errorGeneral
  );
  const { isVisible, isAllowed } = useSelector((state) => state.layout);




  const handleMenu = useCallback(
    (item) => {
      switch (item.key) {
        case "dashboard":
          history.push(pathnameCONFIG.DASHBOARD.BASE_URL);
          break;
        case "paket":
          history.push('/paket/umroh');
          break;
        case "data_master":
          history.push(pathnameCONFIG.DATAMASTER.BASE_URL);
          break;
        case "tagihan":
          history.push('/tagihan/umroh');
          break;
        case "cabang":
          history.push(pathnameCONFIG.CABANG.BASE_URL);
          break;
        case "role":
          history.push(pathnameCONFIG.ROLE.BASE_URL);
          break;
        case "member":
          history.push(pathnameCONFIG.MEMEBER.BASE_URL);
          break;
        case "booking":
          history.push('/booking/umroh');
          break;
        case "setting":
          history.push(pathnameCONFIG.SETTING.BASE_URL);
          break;
        case "calculator":
          history.push(pathnameCONFIG.CALCULATOR.BASE_URL);
          break;
        case "apps":
          history.push(pathnameCONFIG.APPS.BASE_URL);
          break;
        case "marketing":
          history.push(pathnameCONFIG.MARKETING.BASE_URL);
          break;
        case "fee":
          history.push(pathnameCONFIG.FEE.BASE_URL);
          break;
        case "layanan_islami":
          history.push(pathnameCONFIG.LAYANANISLAMI.BASE_URL);
          break;
        default:
          history.push(pathnameCONFIG.DASHBOARD.BASE_URL);
      }
    },
    [history]
  );
  const {
    token: { colorBgContainer },
  } = theme.useToken();
  return (
    <>
      {!isAllowed ? (
        <UnauthorizedPage />
      ) : (
        <Layout className={classes.layout}>
          {/* <ScrollCustom height={"100vh"}> */}

          <Sidebar
            menuList={menuSidebarList}
            handleClick={(val) => handleMenu(val)}
          />
          {/* </ScrollCustom> */}

          <Layout
            style={{
              width: "100%",
            }}
          >
            <HeaderLayout />
            <Content
              className={classes.content}
              style={
                !secondContainer
                  ? {
                      width: '100%',
                      maxHeight: "100vh",
                      overflowX: "hidden",
                      padding: "0 16px 16px 16px",
                    }
                  : null
              }
            >
              {children}
            </Content>
          </Layout>
        </Layout>
      )}
    </>
  );
};
export default Container;
