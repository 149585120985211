import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import Buttons from "components/buttons";

import IconCSV from "assets/icons/CSV.svg";
import AvaIkhwan from "assets/icons/ava-ikhwan.svg";
import AvaAkhwat from "assets/icons/ava-akhwat.svg";

import { getAllCabangService } from "utils/api/cabang"
import { handleError } from "stores/actions/errorGeneral";

import { onHandleGetEquipmentReportAPI, exportLaporanEquipmentService } from "utils/api/paket/laporan";
import { formattorbase64 } from "utils/helpers";

const listCabang = [
  "all",
  "yogyakarta",
  "solo",
  "surabaya",
  "jakarta",
  "palembang",
  "jambi",
  "pekanbaru",
  "medan",
  "aceh",
  "lubuklinggau",
  "makassar",
  "gorontalo",
  "tarakan",
  "banjarmasin",
  "balikpapan",
];

const PaketLaporanPerlengkapan = () => {
  const [activeFilter, setActiveFilter] = useState(null);
  const [branchList, setBranchList] = useState([]);
  const dispatch = useDispatch();
  const params = useParams();
  const [report, setReport] = useState({});
  const auth = useSelector(({auth}) => auth)

  const handleGetCabang = async () => {
    const payload = {
      "per_page": 100,
      "page_number": 1,
      "sort_column": "",
      "sort_order": "Desc",
      "keyword": ""
    }

    const response = await getAllCabangService(payload);
    
    if (response.status === 200) {
      setBranchList(response.data.data)
    } else {
      dispatch(handleError(response.data));
    }

  }

  const handleGetEquipmentReport = async () => {
    const payload = {
        "keyword" : null,
        "sort_column" : "id",
        "sort_order" : "asc",
        "per_page" : 1000,
        "page_number" : 1,
        "branch_id" : activeFilter
    }
    const response = await onHandleGetEquipmentReportAPI(params.id, payload);
    setReport(response.data.data);
  };

  const getFilterCabang = () => {
    return branchList.map((branch, idx) => {
      return (
        <div
          key={idx}
          className={`border-[1px] border-[#4048521a] rounded-lg px-4 py-1 cursor-pointer uppercase font-semibold flex flex-row items-center ${
            activeFilter === branch.id_cabang && "bg-[#F3CFCE]"
          }`}
          onClick={() => setActiveFilter(branch.id_cabang)}
        >
          <div
            className={`flex flex-row justify-center items-center ${
              activeFilter === branch.id_cabang && "txt-maroon"
            }`}
          >
            {branch.name}
          </div>
        </div>
      );
    });
  };

  const handleDownloadCSV = async () => {
    const response = await exportLaporanEquipmentService(params.id);

    if (response.status === 200) {
      const { base64, type, file_name } = response.data.data;
      const insertPrefix = formattorbase64(type, base64);
      var a = document.createElement("a"); //Create <a>
      a.href = `${insertPrefix}`; //Image Base64 Goes here
      a.download = `${file_name}`; //File name Here
      a.click();
    } else {
      dispatch(handleError(response.data));
    }
  };

  useEffect(() => {
    if(auth.user.access_data === 'all_branches') {
      handleGetCabang()
    }
  }, [])

  useEffect(() => {
    handleGetEquipmentReport()
  }, [activeFilter])

  return (
    <div className="bg-white border-[1px] border-[#4048521a] rounded-bl-lg rounded-br-lg">
      {auth.user.access_data === 'all_branches' && (
        <>
          <div className="p-4">
            <div className="font-semibold text-[20px]">Penggunaan Perlengkapan</div>
            <div className="p-4 border-[1px] border-[#4048521a] rounded-lg mt-6">
                  <div className="flex flex-row flex-wrap gap-4">
                    <div
                    className={`border-[1px] border-[#4048521a] rounded-lg px-4 py-1 cursor-pointer uppercase font-semibold flex flex-row items-center ${
                      activeFilter === null && "bg-[#F3CFCE]"
                    }`}
                    onClick={() => setActiveFilter(null)}
                  >
                    <div
                      className={`flex flex-row justify-center items-center ${
                        activeFilter === null && "txt-maroon"
                      }`}
                    >
                      ALL
                    </div>
                  </div>
                {getFilterCabang()}
              </div>
            </div>
          </div>
        </>  
      )}
      <div className="divider"></div>
      <div className="p-4">
        <div className="flex flex-row justify-between gap-4">
          <div className="flex flex-row gap-4 bg-white border-[#4048521a] rounded-lg shadow-soft p-4">
            <div className="flex flex-col">
              <div
                className={`border-[1px] border-[#4048521a] bg-[#F3CFCE] rounded-lg px-4 py-1 cursor-pointer uppercase font-semibold w-fit`}
              >
                <div className={`txt-maroon uppercase`}>All</div>
              </div>
              <div className="font-semibold text-[16px] mt-4">
                Total Perlengkapan
              </div>
            </div>
            <div className="flex flex-row items-center">
              <div className="!rounded-tl-lg !rounded-bl-lg !rounded-tr-none !rounded-br-none dark-grey-bg-shine p-4">
                <div className="txt-green text-[20px] font-semibold">
                  <span className="text-[16px]">{report.total_equipment_ordered}</span>/{report.total_equipment}
                </div>
              </div>
              <div className="flex flex-row gap-3 p-4 border-[1px] border-[#4048521a] rounded-tr-lg rounded-br-lg">
                <div className="flex flex-row gap-2">
                  <img src={AvaIkhwan} alt="ikhwan" />
                  <div className="txt-green font-semibold text-[16px]">{report.total_equipment_man}</div>
                </div>
                <div className="flex flex-row gap-2">
                  <img src={AvaAkhwat} alt="akhwat" />
                  <div className="txt-green font-semibold text-[16px]">{report.total_equipment_girl}</div>
                </div>
              </div>
            </div>
          </div>
          {/* <Buttons
            text="Export CSV"
            customClass="btn-outline btn-small"
            icon={IconCSV}
            onClick={handleDownloadCSV}
          /> */}
        </div>

        <div className="grid grid-cols-5 gap-4 mt-5">
          {report.equipments?.map((equipment, index) => (
            <>
              <div className="bg-white shadow-soft rounded-lg">
              <div className="flex flex-col justify-between items-center dark-grey-bg-shine !rounded-bl-none !rounded-br-none gap-3">
                <div className="text-white font-semibold">{equipment.equipment_name}</div>
                <div className="txt-green text-[20px] font-semibold">
                  <span className="text-[16px]">{equipment.total_order}</span>/{equipment.total_quota}
                </div>
              </div>
              <div className="flex flex-row justify-center gap-6 px-4 py-6">
                <div className="flex flex-row gap-2">
                  <img src={AvaIkhwan} alt="ikhwan" />
                  <div className="txt-green font-semibold text-[16px]">{equipment.total_order_by_man}</div>
                </div>
                <div className="flex flex-row gap-2">
                  <img src={AvaAkhwat} alt="akhwat" />
                  <div className="txt-green font-semibold text-[16px]">{equipment.total_order_by_girl}</div>
                </div>
              </div>
            </div>
            </>
          ))}
        </div>
      </div>
    </div>
  );
};

export default React.memo(PaketLaporanPerlengkapan);
