import React from "react";
import PropTypes from "prop-types";
import OtpInput from "react-otp-input";

import InputOTPStyle from "./InputOTP.style";

const InputOTP = ({
  containerClass,
  customClass,
  value,
  numInputs,
  disabled,
  error,
  onChange,
}) => {
  return (
    <InputOTPStyle className={`${containerClass}`}>
      <OtpInput
        containerStyle={`custom-otp ${customClass}`}
        value={value}
        numInputs={numInputs}
        isDisabled={disabled}
        disabledStyle={disabled ? "custom-otp--disabled" : ""}
        hasErrored={error}
        errorStyle={error ? "custom-otp--error" : ""}
        onChange={onChange}
      />
    </InputOTPStyle>
  );
};

InputOTP.propTypes = {
  containerClass: PropTypes.string,
  customClass: PropTypes.string,
  // value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  numInputs: PropTypes.number,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  onChange: PropTypes.func,
};

InputOTP.defaultProps = {
  containerClass: "",
  customClass: "",
  value: "",
  numInputs: 4,
  disabled: false,
  error: false,
  onChange: () => {},
};

export default InputOTP;
