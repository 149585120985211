

import {
  SET_LOADING
} from "stores/actions/exports/package-report-starting/index";

const initialState = {
  loading: false,
};

export default (state = initialState, { payload, type }) => {
  switch (type) {
    case SET_LOADING:
      return {
        ...state,
        loading: payload,
      };
    default:
      return state;
  }
};