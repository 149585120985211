import React, { useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import Buttons from "components/buttons";
import Badge from "components/badge";
import LocationDetailHover from "./components/LocationDetailHover";
import Popup from "components/popup";
import LocationExportCSV from "./components/ExportCSV";

import IconCSV from "assets/icons/CSV.svg";
import IconEyeRed from "assets/icons/eye-red.svg";
import IconLocation from "assets/icons/location-black.svg";
import {
  exportLaporanManasikLokasi,
  getLaporanManasikLokasi,
} from "stores/actions/paket/laporan";
import { formattorbase64 } from "utils/helpers";

const Location = () => {
  const [hoveredPaketIndex, setHoveredPaketIndex] = useState(null);
  const [openPopup, setOpenPopup] = useState(false);
  const { isLoading, dataLaporanManasikLokasi } = useSelector(
    (state) => state.laporan
  );
  console.log("state", dataLaporanManasikLokasi);

  const dispatch = useDispatch();

  let { id } = useParams();

  useEffect(() => {
    dispatch(getLaporanManasikLokasi(id));
  }, []);

  const handlePaketCardHover = (index) => {
    setHoveredPaketIndex(index);
  };

  const handlePaketCardUnhover = () => {
    setHoveredPaketIndex(null);
  };

  const handleExportCSV = () => {
    dispatch(
      exportLaporanManasikLokasi(id, (message) => {
        const { base64, type, file_name } = message;
        const insertPrefix = formattorbase64(type, base64);
        var a = document.createElement("a"); //Create <a>
        a.href = `${insertPrefix}`; //Image Base64 Goes here
        a.download = `${file_name}`; //File name Here
        a.click();
      })
    );
  };

  return (
    <>
      {/* <Popup
        open={openPopup}
        handleClose={() => setOpenPopup(false)}
        title="Export CSV"
        width="1080px"
        children={<LocationExportCSV />}
      /> */}
      <div className="flex flex-row justify-between items-center p-4 pt-0 mt-4">
        <div className="font-semibold text-[16px]">Daftar Cabang</div>
        {/* <Buttons
          text="Export CSV"
          customClass="btn-outline btn-small"
          icon={IconCSV}
          onClick={handleExportCSV}
        /> */}
      </div>
      <div className="divider"></div>
      <div className="p-4">
        <Grid container spacing={2}>
          {dataLaporanManasikLokasi?.map((item, i) => {
            return (
              <Grid item md={3}>
                <div key={i} className="">
                  {hoveredPaketIndex === i && (
                    <LocationDetailHover
                      data={item?.manasiks}
                      label={item?.branch_name}
                    />
                  )}
                  <div className="bg-white soft-shadow rounded-lg p-4">
                    <div className="flex flex-row justify-between items-center">
                      <Badge
                        className="h-max"
                        label={item?.branch_name}
                        color={item?.branch_text_color}
                        backgroundColor={item?.branch_bg_color}
                      />
                      <Buttons
                        text="Lihat"
                        customClass="btn-outline btn-small !px-2 !py-[6px]"
                        icon={IconEyeRed}
                        iconPosition="right"
                        onMouseEnter={() => handlePaketCardHover(i)}
                        onMouseLeave={() => handlePaketCardUnhover()}
                      />
                    </div>
                    {item?.manasiks?.map((detail, idx) => {
                      return (
                        <div
                          className="border-[1px] border-[#4048521a] rounded-lg px-4 py-3 mt-6"
                          onMouseEnter={() => handlePaketCardHover(i)}
                          onMouseLeave={() => handlePaketCardUnhover()}
                          key={detail?.id_manasik}
                        >
                          <div className="bg-[#F3CFCE] rounded-lg px-4 py-1 w-min">
                            <div className="txt-maroon font-medium">
                              {idx + 1}
                            </div>
                          </div>
                          <div className="flex flex-row gap-2 mt-2">
                            <img src={IconLocation} alt="location" />
                            <div>{detail?.lokasi}</div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </Grid>
            );
          })}
        </Grid>
      </div>
    </>
  );
};

export default React.memo(Location);
