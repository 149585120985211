import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import InputPhone from "../../../components/form/inputPhone";
import Inputs from "../../../components/form/inputs";
import Selects from "../../../components/form/selects";
import Popup from "../../../components/popup";
import FlagIndonesia from "../../../assets/icons/flag-indonesia.svg";
import ImageField from "../../../components/image_field";
import Buttons from "../../../components/buttons";
import { useDispatch, useSelector } from "react-redux";
import { getRegion } from "../../../stores/actions/cabang";
import useDebounce from "utils/helpers/useDebounce";
import MemberAction from "stores/actions/member/admin";
import { handleSuccess } from "stores/actions/errorGeneral";
import { AdminMemberAPI } from "utils/api/member";
import uuid from "react-uuid";
import { setDataUpload } from "stores/actions/uploadFile";

function CreateAdmin({ show, close }) {
  const dispatch = useDispatch();
  const roleStore = useSelector(({ role }) => role?.dataRoleV2?.data?.data);

  const admin = useSelector(({ admin }) => admin);
  const { isSaveLoading } = useSelector(({ admin }) => admin);
  const id_admin = admin?.id_admin;
  const cabangStore = useSelector(({ cabang }) => cabang.dataAllCabang.data);

  const [cabang, setCabang] = useState([]);
  const [role, setRole] = useState([]);
  const modalTitle = id_admin !== "0" ? "Edit Data Admin" : "Tambah Data Admin";
  const [isLoadingAdd, setIsloadingAdd] = useState(false);
  const [titleData] = useState([
    {
      value: "Tn",
      label: "Tuan",
    },
    {
      value: "Ny",
      label: "Nyonya",
    },
    {
      value: "Nn",
      label: "Nona",
    },
  ]);

  useEffect(() => {
    if (cabangStore) {
      setCabang(cabangStore);
    }
    if (roleStore) {
      setRole(roleStore);
    }
  }, [cabangStore, roleStore]);

  const validation = useFormik({
    initialValues: {
      name: "",
      email: null,
      title: "",
      nickname: "",
      password: null,
      city: "",
      phone_number: "",
      role_id: "",
      is_email_confirmed: "1",
      image_profile: "",
      cabang_id: "",
    },
    validationSchema: Yup.object().shape(
      id_admin === "0"
        ? {
            name: Yup.string().required("Nama lengkap wajib diisi"),
            email: Yup.string().required("Email wajib diisi"),
            nickname: Yup.string().required("Nama panggilan wajib diisi"),
            password: Yup.string().required("Kata sandi wajib diisi"),
            phone_number: Yup.string().required("Telepon wajib diisi"),
          }
        : {
            name: Yup.string().required("Nama lengkap wajib diisi"),
            email: Yup.string().required("Email wajib diisi"),
            nickname: Yup.string().required("Nama panggilan wajib diisi"),
            phone_number: Yup.string().required("Telepon wajib diisi"),
          }
    ),
    onSubmit: (val) => {
      if (id_admin === "0") {
        setIsloadingAdd(true);
        dispatch(
          MemberAction.createData(val, callbackPost, (err) => {
            setIsloadingAdd(false);

            const backendErrors = err?.data?.data;

            if (err.status === 400) {
              Object.keys(backendErrors).forEach((field) => {
                validation.setFieldError(field, backendErrors[field][0]);
              });
            }
          })
        );
      } else {
        setIsloadingAdd(true);
        dispatch(
          MemberAction.updatedAdmin(val, id_admin, callbackPost, (message) => {
            setIsloadingAdd(false);
          })
        );
      }
    },
  });

  const callbackPost = (message) => {
    setIsloadingAdd(false);
    validation.resetForm();
    dispatch(MemberAction.fetchData());
    close();
    dispatch(handleSuccess(message));
  };

  const { region } = useSelector((state) => state.cabang);
  const [searchTerm, setSearchTerm] = useState("Jakarta");
  const [cabangAccess, setCabangAccess] = useState("0");
  const debounceLocation = useDebounce(searchTerm, 500);
  const [listRole, setListRole] = useState([]);

  useEffect(() => {
    if (debounceLocation !== "") {
      dispatch(
        getRegion({
          keyword: debounceLocation,
        })
      );
    }
  }, [debounceLocation, dispatch]);

  useEffect(() => {
    if (id_admin !== "0" || id_admin !== 0) {
      AdminMemberAPI.getDetail(id_admin).then((res) => {
        if (res.status === 200) {
          let results = res.data.data;
          validation.setFieldValue("name", results?.name);
          validation.setFieldValue("email", results?.email);
          validation.setFieldValue("title", results?.title);
          validation.setFieldValue("nickname", results?.nickname);
          validation.setFieldValue("password", results?.password);
          validation.setFieldValue("phone_number", results?.phone_number);
          validation.setFieldValue("image_profile", results?.image_profile);
          validation.setFieldValue("role_id", results?.role_id);
          validation.setFieldValue(
            "is_email_confirmed",
            results?.is_email_confirmed
          );
          validation.setFieldValue("cabang_id", results?.cabang_id);

          let image = [
            {
              id: uuid(),
              fileName: "Profil photo",
              path: results.image_profile,
              name: "image_profile",
            },
          ];

          dispatch(setDataUpload(image));
        }
      });
    }
  }, [id_admin]);

  useEffect(() => {
    if (role) {
      const result_map = role?.map((item) => {
        return {
          label: item?.display_name,
          value: item?.id,
        };
      });

      setListRole(result_map);
    }
  }, [role]);

  return (
    <Popup
      containerClass="!z-[1000]"
      width="1184px"
      open={show}
      handleClose={close}
      title={modalTitle}
    >
      <div className="w-full grid grid-cols-2 gap-4">
        <div>
          <Inputs
            name="name"
            customeLabel="!font-normal !text-[12px] !text-[#4F4F4F] !mb-[8px]"
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
            value={validation.values.name}
            label="Nama Lengkap"
            placeholder="Masukan"
            error={
              validation.touched.name &&
              validation.errors.name &&
              validation.errors.name
            }
          />
          <div className="grid grid-cols-3 gap-3 mt-4">
            <Selects
              label="Title"
              customeLabel="!font-normal !text-[12px] !text-[#4F4F4F] !mb-[8px]"
              placeholder="Select item(s)"
              value={[
                {
                  value: validation.values.title,
                  label: titleData.find(
                    (item) => item.value === validation.values.title
                  )?.label,
                },
              ]}
              onChange={(e) => validation.setFieldValue("title", e[0].value)}
              options={titleData}
              error={
                validation.touched.title &&
                validation.errors.title &&
                validation.errors.title
              }
            />

            <div className="col-span-2">
              <Inputs
                customeLabel="!font-normal !text-[12px] !text-[#4F4F4F] !mb-[8px]"
                label="Nama Panggilan"
                placeholder="Masukan"
                name="nickname"
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.nickname}
                error={
                  validation.touched.nickname &&
                  validation.errors.nickname &&
                  validation.errors.nickname
                }
              />
            </div>
          </div>

          <div className="mt-4">
            <Inputs
              customeLabel="!font-normal !text-[12px] !text-[#4F4F4F] !mb-[8px]"
              label="Email"
              name="email"
              placeholder="Masukan"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values.email}
              error={
                validation.touched.email &&
                validation.errors.email &&
                validation.errors.email
              }
            />
          </div>
          <div className="mt-4">
            <InputPhone
              label="Nomor Telepon"
              placeholder="Masukan"
              customeLabel="!font-normal !text-[12px] !text-[#4F4F4F] !mb-[8px]"
              name="phone_number"
              value={validation.values.phone_number}
              error={
                validation.touched.phone_number &&
                validation.errors.phone_number &&
                validation.errors.phone_number
              }
              onChange={(value) =>
                validation.setFieldValue("phone_number", value)
              }
              onBlur={validation.handleBlur}
              phoneRegion="'+62'"
              containerClass="mt-2"
              icon={FlagIndonesia}
              enableSearch
            />
          </div>

          <div className="mt-4">
            <Selects
              label="Role"
              customeLabel="!font-normal !text-[12px] !text-[#4F4F4F] !mb-[8px]"
              placeholder="Select item(s)"
              value={[
                {
                  label: listRole.find(
                    (item) => item.value === validation.values.role_id
                  )?.label,
                  value: validation.values.role_id,
                },
              ]}
              options={listRole}
              onChange={(val) => {
                validation.setFieldValue("role_id", val[0]?.value);
                // setCabangAccess(val[0]?.access_cabang);
              }}
              error={
                validation.touched.role_id &&
                validation.errors.role_id &&
                validation.errors.role_id
              }
            />
          </div>

          <div className="mt-5">
            <Inputs
              customeLabel="!font-normal !text-[12px] !text-[#4F4F4F] !mb-[8px]"
              type="password"
              label="Kata Sandi"
              placeholder="Masukan"
              name="password"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values.password}
              error={
                validation.touched.password &&
                validation.errors.password &&
                validation.errors.password
              }
            />
          </div>

          <div className="mt-4">
            <Selects
              customeLabel="!font-normal !text-[12px] !text-[#4F4F4F] !mb-[8px]"
              label="Status Email"
              placeholder="Select item(s)"
              value={[
                {
                  label:
                    validation.values?.is_email_confirmed === 1
                      ? "Verified"
                      : "Unverified",
                  value: validation.values?.is_email_confirmed,
                },
              ]}
              onChange={(e) =>
                validation.setFieldValue("is_email_confirmed", e[0].value)
              }
              options={[
                {
                  value: 1,
                  label: "Verified",
                },
                {
                  value: 0,
                  label: "Unverified",
                },
              ]}
            />
          </div>
          <div className="mt-4">
            <Selects
              customeLabel="!font-normal !text-[12px] !text-[#4F4F4F] !mb-[8px]"
              label="Cabang"
              placeholder="Select item(s)"
              value={[
                {
                  label: cabang
                    ?.map((v) => {
                      return {
                        value: v.id_cabang,
                        label: v.name,
                      };
                    })
                    .find((item) => item.value === validation.values?.cabang_id)
                    ?.label,
                  value: validation.values?.id_cabang,
                },
              ]}
              options={cabang?.map((v) => {
                return {
                  value: v.id_cabang,
                  label: v.name,
                };
              })}
              onChange={(val) =>
                validation.setFieldValue("cabang_id", val[0]?.value)
              }
            />
          </div>
        </div>

        <div>
          <ImageField
            customeLabel="!font-normal !text-[12px] !text-[#4F4F4F] !mb-[8px]"
            customContainer="h-[248px]"
            setFile={(file) =>
              validation.setFieldValue("image_profile", file?.path)
            }
            label="Foto Profil"
            name="image_profile"
          />

          <Buttons
            disabled={!(validation.isValid && validation.dirty)}
            onClick={() => validation.handleSubmit()}
            text="Simpan"
            loading={isLoadingAdd}
            customClass="btn-primary btn-medium mt-4"
          />
        </div>
      </div>
    </Popup>
  );
}

export default CreateAdmin;
