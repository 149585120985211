import React, { useEffect } from 'react';

import { toast, ToastContainer } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';
import "./style.css";

import ChecklistBuletGreen from '../../assets/icons/checklist-bulet-green.svg'
import CloseBuletMaroon from '../../assets/icons/close-bulet-maroon.svg'
import WarningBuletWarning from '../../assets/icons/warning-bulet-yellow.svg'

import PropTypes from "prop-types";

const Toastfy = ({
    show,
    type,
    autoClose,
    handleClose,
    message,
    hideProgressBar,
    position,
    title
}) => {

    useEffect(() => {
        if(show) {
            notify()
        }
    }, [show])

    const notify = () => {
        if(type == 'success') {
            toast(
            <>
                <div className='content'>
                    <div className='icon'>
                        <img src={ChecklistBuletGreen} className='h-12 w-12 max-w-none' />
                    </div>
                    <div className='section'>
                        <h3 className='title'>{title ?? `Berhasil`}</h3>
                        <span className='message'>{message}</span>
                    </div>
                </div>
            </>, {
                autoClose: autoClose,
                onClose: handleClose,
            });
        }

        if(type == 'error') {
            toast(
            <>
                <div className='content'>
                    <div className='icon'>
                        <img src={CloseBuletMaroon} className='h-12 w-12 max-w-none' />
                    </div>
                    <div className='section'>
                        <h3 className='title'>{title ?? `Gagal`}</h3>
                        <span className='message line-clamp-2'>{message}</span>
                    </div>
                </div>
            </>, {
                autoClose: autoClose,
                onClose: handleClose,
            });
        }

        if(type == 'warning') {
            toast(
            <>
                <div className='content'>
                    <div className='icon'>
                        <img src={WarningBuletWarning} className='h-12 w-12 max-w-none' />
                    </div>
                    <div className='section'>
                        <h3 className='title'>{title ?? `Peringatan`}</h3>
                        <span className='message line-clamp-2'>{message}</span>
                    </div>
                </div>
            </>, {
                autoClose: autoClose,
                onClose: handleClose,
            });
        }
    }

    return (
        <>  
            {show && (
                <>
                    <ToastContainer
                        className={`costum-toastify`}
                        autoClose={autoClose}
                        position={position}
                        hideProgressBar={hideProgressBar}
                        newestOnTop={false}
                        draggable={false}
                    />
                </>
            )}
        </>
    )   
}

Toastfy.propTypes = {
    show: PropTypes.bool,
    type: PropTypes.string,
    autoClose: PropTypes.number,
    message: PropTypes.string,
    handleClose: PropTypes.func,
    hideProgressBar: PropTypes.bool,
    position: PropTypes.string,
    title: PropTypes.string
}

Toastfy.defaultProps = {
    show: false,
    type: 'success',
    autoClose: 5000,
    message: null,
    hideProgressBar: true,
    position: "bottom-right",
    title: null
}

export default React.memo(Toastfy)