import { makeStyles } from "@material-ui/core";
import "./travel.scss";
// import "./socket";
import Router from "./router";

import { useDispatch } from "react-redux";

import { onMessage } from "firebase/messaging";
import { messaging } from "./firebase/firebaseConfig";
import { useEffect, useState } from "react";

import Toastify from "components/toastify";

// import all notifications actions
import {
  getList,
  getUnreadCount,
  setCountUnread,
} from "stores/actions/notifications";


const useStyle = makeStyles({
  content: {
    height: "100%",
  },
});

function App() {
  const dispatch = useDispatch();
  const classes = useStyle();
  const [notification, setNotification] = useState(null);
  const [showToast, setShowToast] = useState(false);

 
  onMessage(messaging, (payload) => {

    {payload.notification && setShowToast(true)}
    setNotification(payload.notification);
    
    dispatch(getList());
    payload.data?.notificationUnreadCount && dispatch(setCountUnread(payload.data?.notificationUnreadCount));

  });

  return (
    <div className={classes.content}>
      <Router />
      <Toastify 
        type="success"
        show={showToast}
        message={notification?.body}
        title={notification?.title}
        handleClose={() => setShowToast(false)}
        hideProgressBar={true}
        />
    </div>
  );
}


export default App;
