import { useState, useCallback, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  saveDataRole,
  getDataRole,
  updatedeDataRoleV2,
  getDataRolePermisionV2,
} from "../../../stores/actions/role";

import Popup from "../../../components/popup";
import Button from "../../../components/buttons";
import Inputs from "../../../components/form/inputs";
import Textareas from "../../../components/form/textarea";
import Selects from "../../../components/form/selects";
import ColorPicker from "../../../components/color_picker";
import PermissionDropdownOnPopup from "./PermissionDropdownOnPopup";
import { Spin } from "antd";

import { handleSuccess } from "stores/actions/errorGeneral";

const AddEditRolePopup = ({
  open,
  handleOpenPopup,
  handleClosePopup,
  roleList,
  selectedRole,
  handleOnSuccess
}) => {
  const dispatch = useDispatch();
  const {
    dataRole,
    isLoading,
    isLoadingData,
    dataPermission,
    dataRoleV2Utama,
    dataDetail,
    dataRolePermision,
  } = useSelector((state) => state.role);

  const [modalState, setModalState] = useState("");
  const [options, setOptions] = useState([]);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [colorBg, setColorBg] = useState("");
  const [colorText, setColorText] = useState("");
  const [mainRole, setMainRole] = useState([
    {
      label: "",
      value: "",
    },
  ]);
  const [dataPermisionSelect, setDataPermisionSelect] = useState([]);
  const [dataAccess, setDataAcess] = useState([
    {
      label: "",
      value: "",
    },
  ]);
  const [dataListPermision, setDatalistPermision] = useState([]);

  useEffect(() => {
    setMainRole([
      {
        label: "",
        value: "",
      },
    ]);
  }, []);

  const saveRole = async () => {
    let temp = [];
    dataPermisionSelect.length > 0 &&
      dataPermisionSelect?.forEach((val) => {
        val.data_permissions.forEach((item) => {
          if (item.status === 1) {
            temp.push(item.name);
          }
        });
      });
    let payload = {
      name: name,
      description: description,
      bg_color: colorBg,
      permissions: temp,
      access_data: dataAccess[0]?.value,
    };

    if (Object.keys(dataDetail).length > 0) {
      dispatch(
        updatedeDataRoleV2(
          { ...payload },
          dataDetail?.data?.name,
          (message) => {
            handleClosePopup("all");
            dispatch(handleSuccess(null, { message: "Berhasil mengupdate role", code: 200 }));
            handleOnSuccess()
          }
        )
      );
    } else {
      dispatch(
        saveDataRole(payload, (res) => {
          handleClosePopup("all");
          dispatch(handleSuccess(res));
          dispatch(handleSuccess(null, { message: "Berhasil menambahkan role", code: 200 }));
          handleOnSuccess()
        })
      );
    }
  };

  const handleSelectPermision = (data) => {
    setDataPermisionSelect(data);
  };

  useEffect(() => {
    if (Object.keys(dataDetail).length > 0) {
      const data_access = [
        {
          label: "Semua Cabang",
          value: "all_branches",
        },
        {
          label: "Cabang Sendiri",
          value: "self_branch",
        },
      ].find((item) => item.value === dataDetail?.data?.access_data);
      setName(dataDetail?.data?.display_name);
      setDescription(dataDetail?.data?.description);
      setColorBg(dataDetail?.data?.bg_color);
      setDataAcess([data_access]);

      setModalState("Edit");
    } else {
      setName("");
      setDescription("");
      setColorBg("");
      setModalState("Tambah");
    }
  }, [dataDetail]);

  useEffect(() => {
    if (dataRoleV2Utama?.data?.data?.length) {
      setOptions(
        dataRoleV2Utama?.data?.data?.map((i) => ({
          value: i.name,
          label: i.display_name,
        }))
      );
    }
  }, [dataRoleV2Utama]);

  useEffect(() => {
    if (mainRole[0].value !== "") {
      const payload = {
        name: mainRole[0].value,
      };

      dispatch(getDataRolePermisionV2(payload));
    }
  }, [mainRole]);

  useEffect(() => {
    if (Object.keys(dataRolePermision).length > 0) {
      setDatalistPermision(dataRolePermision.data);
    }
  }, [dataRolePermision]);

  return (
    <>
      <Popup
        type="secondary"
        open={open}
        handleClose={handleClosePopup}
        title={`${modalState} Role`}
        width="592px"
        customClass="box-border !z-0 !shadow-[0px_4px_80px_-4px_#0000000A]"
      >
        {!isLoadingData ? (
          <div className="flex flex-col gap-3">
            <Inputs
              id="test"
              placeholder="Masukkan nama role"
              label="Nama Role"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            {Object.keys(dataDetail).length === 0 && (
              <Selects
                label="Role Utama"
                placeholder="Pilih role utama"
                value={mainRole}
                options={options}
                onChange={(e) => setMainRole(e)}
              />
            )}

            <Selects
              label="Data Access"
              placeholder="Pilih data access"
              values={dataAccess.length > 0 && dataAccess}
              options={[
                {
                  label: "Semua Cabang",
                  value: "all_branches",
                },
                {
                  label: "Cabang Sendiri",
                  value: "self_branch",
                },
              ]}
              onChange={(e) => {
                setDataAcess(e);
              }}
            />
            <ColorPicker
              label="Pilih Warna Latar Belakang"
              placeholder="Pilih Warna"
              onChange={(color) => setColorBg(color.hex)}
              color={colorBg}
            />

            <PermissionDropdownOnPopup
              selectedRole={dataPermission.data}
              dataListPermision={dataListPermision}
              setDataPermisionSelect={(data) => handleSelectPermision(data)}
            />
            <Textareas
              placeholder="Masukkan deskripsi"
              label="Deskripsi"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
            <Button
              text={`${selectedRole ? "Simpan" : "Tambahkan"} Role`}
              customClass="btn-primary btn-medium mt-2"
              onClick={saveRole}
              loading={isLoading}
            />
          </div>
        ) : (
          <div className="w-full h-[530px] flex justify-center items-center">
            <Spin size="large" />
          </div>
        )}
      </Popup>
    </>
  );
};

export default AddEditRolePopup;
