import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import { deleteCouponService } from "utils/api/booking";

import Badge from "../../../../components/badge";
import MoslemeTable from "../../../../components/Table";

import { handleGetDetailOrder, handleGetDataJamaah } from "stores/actions/booking";

import Avatar from "components/avatar";
import { IDRFormater, toCapital } from "utils/helpers";

import IconEdit from "assets/icons/edit.svg";
import IconDelete from "assets/icons/trash.svg";
import IconDeleteWhite from "assets/icons/trash-white.svg";

import Alert from "components/popup/alert";
import { handleError, handleSuccess } from "stores/actions/errorGeneral";

const TabTagihan = ({
  handleUpdateAdditional,
  handleDeleteAdditional
}) => {
  const dispatch = useDispatch()
  const params = useParams()

  const { bookingDataJamaah, bookingDetail } = useSelector((state) => state.booking);

  const [jamaahSelected, setJamaahSelected] = useState(null)

  const [confirmationDeleteCoupon, setConfirmationDeleteCoupon] = useState(false)
  const [couponNameSelected, setCouponNameSelected] = useState(null)
  const [couponIdSelected, setCouponIdSelected] = useState(null)
  const [isDeletingCoupon, setIsDeletingCoupon] = useState(false)
  
  

  const handleRoomType = (roomType) => {
    roomType = roomType.replace('_bussiness', ' (Busines Class)')
    roomType = roomType.replace('tripple', 'triple')

    return roomType.toUpperCase()
  }

  const handleDiscountAmount = (coupon, jamaahTotalPrice) => {

    let discount = coupon.nominal_diskon;

    if(coupon.jenis_diskon ==  2) {
      discount = (coupon.nominal_diskon/100) * (jamaahTotalPrice)
    }
   
    return IDRFormater(discount)
  }

  const handleConfirmDelete = (jamaah, coupon) => {
      setJamaahSelected(jamaah)
      setCouponNameSelected(coupon?.code_coupon)
      setCouponIdSelected(coupon?.id_coupon)
      
      setConfirmationDeleteCoupon(true)
  }

  const handleDeleteCoupon = async  () => {
    const payload = {
      "jamaah_order_id": jamaahSelected.id,
      'coupon_id': couponIdSelected
    }

    setIsDeletingCoupon(true)

    const response = await deleteCouponService(params.orderNumber, payload)
    setIsDeletingCoupon(false)

    if(response.status == 200) {
      dispatch(handleSuccess(response))
      dispatch(handleGetDataJamaah(params.orderNumber))
      dispatch(handleGetDetailOrder(params.orderNumber))
    } else {
      dispatch(handleError(response.data))
    }

    setConfirmationDeleteCoupon(false)
    setCouponNameSelected(null)
    setCouponIdSelected(null)

  }

  useEffect(() => {
    dispatch(handleGetDataJamaah(params.orderNumber))
  }, [])

  const columns = [
    {
      title: "Nama",
      dataIndex: "name",
    },
    {
      title: "Jumlah",
      dataIndex: "quantity",
    },
    {
      title: "Catatan",
      dataIndex: "note",
    },
    {
      title: "Harga",
      dataIndex: "price",
      render: (record) => <span>{IDRFormater(record)}</span>
    },
    {
      title: "Harga Total",
      render: (record) => (
        <>
          <span>{IDRFormater(record.price * record.quantity)}</span>
          {bookingDetail?.payment_status !== 4 && (
            <div className="action">
              <div className="action-content">
                <div className="action-content-item">
                  <img
                    className="cursor-pointer"
                    src={IconEdit}
                    alt="edit"
                    onClick={() => handleUpdateAdditional(record)}
                  />
                </div>
                {record?.is_wajib != 1 && (
                  <div className="action-content-item">
                    <img 
                      className="cursor-pointer" 
                      onClick={() => handleDeleteAdditional(record)} 
                      src={IconDelete} alt="delete" />
                  </div>
                )}
              </div>
            </div>
          )}
        </>
      )
    }
  ]

  const CardTagihanJamaah = ({ jamaah }) => {
    return (
      <>
        <Alert 
          open={confirmationDeleteCoupon}
          title={`Yakin akan menghapus kupon ini?`}
          subtitle={`Dihapusnya kupon akan mempengaruhi biaya bookingan`}
          handleClose={() => {
            setConfirmationDeleteCoupon(false)
            setCouponNameSelected(null)
          }}
          handleContinue={() => handleDeleteCoupon()}
          text_accept="Yakin"
          text_cancel="Tidak"
          loading={isDeletingCoupon}
          />

        <div className="card-tagihan-jamaah-wrapper mt-4">
          <div className="card-tagihan-jamaah-header flex flex-row items-center">
            <Avatar value={jamaah} />
            <div className="card-tagihan-jamaah-header-name">
              {toCapital(jamaah.jamaah_title)} {jamaah.jamaah_name.toUpperCase()}
            </div>
          </div>
          <div className="card-tagihan-jamaah-content">
            <div className="flex flex-row justify-between p-4">
              <div className="card-tagihan-jamaah-content-wrapper">
                <div className="card-tagihan-jamaah-content-wrapper-label">
                  Tipe Kamar
                </div>
                <div className="card-tagihan-jamaah-content-wrapper-value">
                  {handleRoomType(jamaah?.room_type)}
                </div>
              </div>
              <div className="card-tagihan-jamaah-content-wrapper">
                <div className="card-tagihan-jamaah-content-wrapper-label">
                  Harga Paket
                </div>
                <div className="card-tagihan-jamaah-content-wrapper-value">
                  {IDRFormater(jamaah.kamar_price)}
                </div>
              </div>
              <div>
                <Badge 
                  label={jamaah.name_package_starting.toUpperCase()}
                  backgroundColor={jamaah.package_starting.bg_color}
                  color={jamaah.package_starting.text_color}
                  />
              </div>
            </div>

            <div className="px-4 mt-1 mb-4">
              <div>Additional</div>
              <MoslemeTable
                // bordered
                columns={columns}
                data={jamaah.paket_additional}
                customClass="tbl-jamaah border mt-4 rounded-xl overflow-hidden"
                withSelection={false}
                selectionType=""
                withPagination={false}
                customPaginationClass="pr-4"
              />
            </div>

            {(jamaah.coupons.length > 0 || jamaah.discount_agent > 0) && (
              <div className="px-4 mt-1 mb-4">
                <div>Diskon</div>
                <div className="flex flex-col gap-2 mt-2">
                  {jamaah.coupons.map((coupon, index) => (
                    <div key={index} className="bg-[#73B17D] py-2 text-white px-4 rounded-xl flex justify-between items-center">
                      <div>
                        Kupon <strong>{coupon.code_coupon}</strong>
                      </div>
                      <div className="flex flex-row gap-2 items-center">
                        <strong>
                          {coupon.jenis_diskon == 2 && `(${coupon.nominal_diskon}%) `}
                          {handleDiscountAmount(coupon, (jamaah.kamar_price + jamaah.additional_price))}
                        </strong>
                        <img 
                          onClick={() => {
                            handleConfirmDelete(jamaah, coupon)
                          }}
                          className="cursor-pointer" 
                          src={IconDeleteWhite}/>
                      </div>
                    </div>
                  ))}
                  {jamaah.discount_agent > 0 && (
                    <>
                      <div className="bg-[#73B17D] py-2 text-white px-4 rounded-xl flex justify-between items-center">
                        <div>
                          Diskon dari Agen
                        </div>
                        <div className="flex flex-row gap-2 items-center">
                          <strong>
                            {IDRFormater(jamaah.discount_agent)}
                          </strong>
                          <img 
                            // onClick={() => {
                            //   handleConfirmDelete(jamaah, coupon)
                            // }}
                            className="cursor-not-allowed" 
                            src={IconDeleteWhite}/>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            )}

          </div>
          <div className="card-tagihan-jamaah-footer p-4">
            <div className="card-tagihan-jamaah-footer-wrapper">
              <div className="card-tagihan-jamaah-footer-wrapper-label">
                Total Tagihan:
              </div>
              <div className="card-tagihan-jamaah-footer-wrapper-name mt-2">
                {IDRFormater(jamaah.total_price)}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      <div className="grid grid-cols-2 gap-2 mt-4"> 
        {!!bookingDataJamaah.length && bookingDataJamaah.map((jamaah, index) => 
          (
            <>
              <CardTagihanJamaah 
                jamaah={jamaah}
                />
            </>
          )
        )}
      </div>
    </>
  );
};

export default TabTagihan;
