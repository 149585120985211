import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { Table } from "antd";

import MoslemeTable from "components/Table";
import Badge from "components/badge";

import AvatarRed from "assets/images/Avatar-ikhwan-red.svg";
import IconWhatsapp from "assets/icons/whatsapp.svg";
import { CloseSquare, DocumentCopy, Edit, Eye, InfoCircle, Trash } from "iconsax-react";
import IconTask from "assets/icons/task-table.svg";

import { handleSendFollowupToJamaahOrder } from "utils/api/booking";

import { handleGetJamaahBookingList } from "utils/api/booking";
import { handleError, handleSuccess } from "stores/actions/errorGeneral";
import Avatar from "components/avatar";
import { toCapital } from "utils/helpers";
import moment from "moment";
import Buttons from "components/buttons";

import IconSistemMaroon from 'assets/icons/icon-sistem-maroon.svg'
import IconAdminMaroon from 'assets/icons/icon-admin-maroon.svg'
import Popup from "components/popup";

const Jamaah = ({ 
  keyword, 
  searchDebounce, 
  tabActive,
  jamaahOrderList, 
  totalJamaahOrderList,
  currentPage,
  pageSize,
  isLoading,
  handleChangePage,
  handleChangePageSize,
  handleCancelOrder
}) => {
  const dispatch = useDispatch();
  const router = useHistory();
  const auth = useSelector(({auth}) => auth)
  const permissions = auth?.user?.permissions;

  const [openCancelReason, setOpenCancelReason] = useState(false)
  const [cancelReason, setCancelReason] = useState('')

  // Expaneble
  const [isExpand, setIsExpand] = useState(true);
  // Selection & type state
  const [selectionType, setSelectionType] = useState("checkbox");
  const [withSelection, setWithSelection] = useState(false);

  const [sendingFollowup, setSendingFollowup] = useState({
    loading: false,
    jamaahOrderID: null
  })

  // Pagination config
  const pagination = {
    showSizeChanger: true,
    total: totalJamaahOrderList,
    current: currentPage,
    pageSize: pageSize,
    defaultPageSize: 10,
    pageSizeOptions: ["10", "20", "50", "100"],
  };

  const handleRoomName = (roomName) => {
    roomName = roomName.replace('_bussiness', '')
    roomName = roomName.replace('tripple', 'triple')

    return toCapital(roomName)
  }

  const handleClickFollowup = async (orderNumber, jamaahOrderID) => {
    // console.log(orderNumber, jamaahOrderID)
    setSendingFollowup({
      loading: true,
      jamaahOrderID: jamaahOrderID
    })
    const res = await handleSendFollowupToJamaahOrder(orderNumber, jamaahOrderID, 'manifest')
    setSendingFollowup({
      loading: false,
      jamaahOrderID: null
    })

    if (res.status === 200) {
      dispatch(handleSuccess(res))
    } else {
      dispatch(handleError(res.data))
    }

  }

  const columns = [
    Table.SELECTION_COLUMN,
    Table.EXPAND_COLUMN,
    {
      title: "Foto",
      width: '32px',
      render: (item) => (
          <>
            {item.order.pemesan ? (
              <>
                <Avatar 
                  customGender={item.jamaah_title?.toLowerCase() == 'ny' || item.jamaah_title?.toLowerCase() == 'nn' ? 'akhwat' : 'ikhwan' }
                  imageTemp={item.jamaah_photo}
                  />
              </>
            ) :'-'}
          </>
      ) 
    },
    {
      title: "Jamaah",
      render: (item) => (
            <>
              <div className="mb-1">
               {item.jamaah_title ? `${toCapital(item.jamaah_title.toUpperCase())}.` : ''} {item.jamaah_name.toUpperCase()}
              </div>
              <div className="flex gap-1">
              <Badge 
                  label={item.starting?.name?.toUpperCase()}
                  backgroundColor={item.starting?.bg_color}
                  color={item.starting?.text_color}
                  />
              <Badge 
                  label={item.branch?.name?.toUpperCase()}
                  backgroundColor={item.branch?.bg_color}
                  color={item.branch?.text_color}
                  />
              </div>
            </>
      )
    },
    {
      title: "No Whatsapp",
      render: (item) => (
        <>
          {item.jamaah_phone}
        </>
      )
    },
    {
      title: "Kode Booking",
      render: (record) => (
        <>
          #{record.order.order_number}
        </>
      ),
    },
    {
      title: "Dokumen",
      render: (order) => (
        <div 
          className={`flex flex-row items-center gap-1 border ${order.total_filled_documents ? `bg-[#D8E1BE] border-[#71BE0E]` : `border-[#E0E0E0]`} rounded-lg px-3 py-[6px] w-fit`}>
          <img src={IconTask} alt="task" />
          <div>Dokumen</div>
          <div>({order.total_filled_documents}/{order.total_documents})</div>
        </div>
      ),
    },
    {
      title: "Perlengkapan",
      render: (order) => (
        <div 
          className={`flex flex-row items-center gap-1 border ${order.total_filled_manifests ? `bg-[#D8E1BE] border-[#71BE0E]` : `border-[#E0E0E0]`} rounded-lg px-3 py-[6px] w-fit`}>
          <img src={IconTask} alt="task" />
          <div>Perlengkapan</div>
          <div>({order.total_filled_manifests}/{order.total_manifests})</div>
        </div>
      ),
    },
    {
      title: "Status",
      render: (record) => (
        <>
          {record.order.name_payment_status === 'Belum DP' && (
            <Badge 
              backgroundColor={'#F7A399'}
              color={"#FE0000"}
              label={record.order.name_payment_status.toUpperCase()} />
          )}
          {record.order.name_payment_status === 'Sudah DP' && (
            <Badge 
              backgroundColor={'#BBDEFB'}
              color={"#0000FE"}
              label={`SUDAH DP`} />
          )}
          {record.order.name_payment_status === 'Belum Lunas' && (
            <Badge 
              backgroundColor={'#FEDCED'}
              color={"#F20089"}
              label={`BELUM LUNAS`} />
          )}
          {record.order.name_payment_status === 'Lunas' && (
            <Badge 
              backgroundColor={'#92E6A7'}
              color={"#1A7431"}
              label={record.order.name_payment_status.toUpperCase()} />
          )}
          {record.order.name_payment_status === 'Di Batalkan' && (
            <Badge 
              backgroundColor={'#CED4DA'}
              color={"#6C757D"}
              label={<>
                <div className="flex justify-center items-center gap-2">
                  <span>
                    {`DIBATALKAN`}
                  </span>
                  <div>
                    {record.order.cancel_by > 0 ? <img src={IconAdminMaroon} /> : <img src={IconSistemMaroon} />}
                  </div>
                </div>
              </>} />
          )}
        </>
      ),
    },
    {
      title: "Paket",
      render: (data) => (
        <>
          <>
            <div>
              <div className="font-semibold">{data?.order?.paket?.judul_paket}</div>
              <div>Keberangkatan <span className="font-semibold">{moment(data?.order?.paket?.jadwal_keberangkatan).format("DD MMMM YYYY")}</span></div>
            </div>
            
          </>
          <div className="action">
            <div className="action-content">
              <div className="action-content-item">
                <Eye
                  className="cursor-pointer"
                  onClick={() => router.push(`/booking/detail/${data?.order.order_number}`)}
                  size="15"
                  color="#141414"
                />
              </div>
              {data?.order?.name_payment_status === 'Di Batalkan' && (
                <div className="action-content-item">
                  <InfoCircle 
                    size="15"
                    color="#141414"
                    className="cursor-pointer"
                    onClick={() => {
                      setOpenCancelReason(true)
                      setCancelReason(data?.order.cancel_reason)
                    }}
                  />
                </div>
              )}
            </div>
          </div>
        </>
      ),
    },
  ];

  // if have expaneble, this is the props example..
  const expandable = {
    expandedRowRender: (record) => {
      return (
        <div className="ml-2">
          <ul className="w-full flex py-3 border-l">
            <li className="w-[15rem]">
              <ul>
                <li className="border-b pl-5 pb-3 font-bold">
                  Tanggal Booking
                </li>
              </ul>
            </li>
            <li className="w-[15rem]">
              <ul>
                <li className="border-b pl-5 pb-3 font-bold">Follow Up</li>
              </ul>
            </li>
            <li className="w-full">
              <ul>
                <li className="border-b pl-5 pb-3 font-bold">Tipe Kamar</li>
              </ul>
            </li>
          </ul>
          <ul className="w-full flex items-center pb-3 border-l">
            <li className="w-[15rem]">
              <ul>
                <li className="pl-5 pt-2">
                  <div>
                    {moment(record.order.created_at).format("dddd, DD MMMM YYYY H:mm")} WIB
                  </div>
                </li>
              </ul>
            </li>
            <li className="w-[15rem]">
              <ul>
                <li className="pl-5 pt-2">
                <Buttons
                    text=""
                    customClass="btn-brown-light btn-medium !w-fit"
                    icon={IconWhatsapp}
                    onClick={() => handleClickFollowup(record.order.order_number, record.id)}
                    loading={sendingFollowup.loading && (sendingFollowup.jamaahOrderID == record.id)}
                    loadingIcon={true}
                    disabled={(record.order.payment_status == 0 || record.order.payment_status == 4) || record.dokumen_terisi_persen == 100}
                  />
                </li>
              </ul>
            </li>
            <li className="w-full">
              <ul>
                <li className="pl-5 pt-2 flex gap-2">
                  <Badge
                    backgroundColor="#92E6A7"
                    color="#141414"
                    label={
                      <div className="flex items-center">
                        <div className="text-[#1B662E] font-semibold">
                          {handleRoomName(record.kamar_name)}
                        </div>
                      </div>
                    }
                  />
                </li>
              </ul>
            </li>
          </ul>
        </div>
      );
    },
    rowExpandable: (record) => record.booking_code !== "Not Expandable",
  };

  return (
    <div className="mx-[-14px]">
      <Popup
        open={openCancelReason}
        handleClose={() => {
          setCancelReason('')
          setOpenCancelReason(false)
        }}
        title={`Alasan dibatalkan`}
        width="550px"
        containerClass=""
        children={
          <>
            <div className="p-4 border rounded-2xl">
              {cancelReason}
            </div>
          </>
        }
        
      />
      {/* Table */}
      <MoslemeTable
        loading={isLoading}
        expandable={isExpand && expandable}
        columns={columns}
        data={jamaahOrderList}
        withSelection={true}
        selectionType={selectionType}
        pagination={pagination}
        customPaginationClass="pr-4"
        customClass="mt-4"
        onPageChange={(page) => handleChangePage(page)}
        onSizeChange={(currentpage, pageSize) => {
          handleChangePage(currentpage);
          handleChangePageSize(pageSize);
        }}
      />
    </div>
  );
};

export default React.memo(Jamaah);
