import React from "react";
import PropTypes from "prop-types";
import { DatePicker as AntDatePicker } from "antd";
import "./datepicker.scss";
import DatePickerIcon from "./icons/DatePickerIcon";
import Close from "assets/icons/Close.svg";
import moment from "moment";

const CalendarIcon = <DatePickerIcon />;

const DatePicker = ({
  containerClass,
  customClass,
  placeholder,
  value,
  formatManual,
  onChange,
  onBlur,
  CalendarIcon = <DatePickerIcon />,
  error,
  disabled,
  values
}) => {
  const formatDateValue = (val) => {
    return moment(val?.$d, "DD-MM-YYYY").format("DD-MM-YYYY");
  };

  const onChangeHandler = (val) => {
    if (formatManual) {
      return onChange(val);
    } else {
      onChange(formatDateValue(val));
    }
  };

  return (
    <div className={`${containerClass}`}>
      <AntDatePicker
        className={`${customClass} single-date-picker`}
        placeholder={[placeholder]}
        popupClassName="popup-date-picker"
        onChange={onChangeHandler}
        onBlur={onBlur}
        disabled={disabled}
        suffixIcon={CalendarIcon}
        showToday={false}
        format="DD-MM-YYYY"
        value={values}
        defaultValue={value}
        getPopupContainer={() => document.getElementById("area-datepicker")}
      />
      <div id="area-datepicker"></div>
      {error && (
        <div className="flex flex-row items-center mt-1 gap-x-2">
          <img src={Close} alt="close" />
          <div className="text-red-500">{error}</div>
        </div>
      )}
    </div>
  );
};

DatePicker.propTypes = {
  containerClass: PropTypes.string,
  customClass: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  formatManual: PropTypes.bool,
  value: PropTypes.string,
  disabled: PropTypes.bool
};

DatePicker.defaultProps = {
  containerClass: "",
  customClass: "",
  placeholder: "",
  formatManual: false,
  onChange: () => {},
  onBlur: () => {},
  value: "11-11-2020",
  disabled: false
};

export default DatePicker;
