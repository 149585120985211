import React, { useEffect, useState } from "react";
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'

import Buttons from "components/buttons"
import Scroll from "components/scroll"
import { IDRFormater } from "utils/helpers";
import Dropdowns, { closeDropdown } from "components/dropdowns";

import IconChecklisted from "assets/icons/Checklisted.svg";
import AdditionalJamaahForm from "./AdditionalJamaahForm";
import AdditionalJamaahCard from "./AdditionalJamaahCard";

import { getAdditionalByPaket } from "utils/api/paket/additional";
import { handleAddAdditionalAPI } from 'utils/api/booking'

import { useFormik } from "formik"
import * as Yup from 'yup'

import { handleGetDetailOrder, handleGetDataJamaah } from "stores/actions/booking";

import { handleError, handleSuccess } from "stores/actions/errorGeneral";


const AddAdditional = ({
    packageID,
    order,
    handleAddSuccess
}) => {
    const dispatch = useDispatch()
    const params = useParams()

    const [additionalLabel, setAdditionalLabel] = useState('Pilih Additional')

    const [keyword, setKeyword] = useState('')
    const [additionals, setAdditionals] = useState([])
    const [additionalSelected, setAdditionalSelected] = useState(null)

    const [additionalSelectedIndex, setAdditionalSelectedIndex] = useState(0)
    const [isAdditionalSelected, setIsAdditionalSelected] = useState(false)

    const [isLoading, setIsLoading] = useState(false)

    const additionalsFormik = useFormik({
        initialValues: {
            "paket_additional_id": null,
            "list_jamaah": []
        },
        alidationSchema: Yup.object({
            paket_additional_id: Yup.number().required('ID harus diisi dan harus berupa angka'),
            list_jamaah: Yup.array().required('List jamaah harus diisi').min(1).of(
                Yup.object().shape({
                    order_kamar_mapping_id: Yup.number().required('ID harus diisi dan harus berupa angka'),
                    quantity: Yup.number().required('Quantity harus diisi dan harus berupa angka')
                })
            )
        })
    })

    const handleGetAdditionalPackage = async () => {
        const payload = {
          id_paket: packageID,
          keyword,
          per_page: 100,
          page_number: 1,
          sort_column: 'name',
          sort_order: 'ASC'
        }
        const response = await getAdditionalByPaket(payload)
        const { status, data } = response
    
        if(status == 200) {
          setAdditionals(data.data)
        } else {
          dispatch(handleError(response.data))
        }
    
    }

    const handleAdditionalSelecting = (additional) => {
        setAdditionalSelected(additional)
        setAdditionalLabel(additional.name)

        additionalsFormik.setFieldValue('paket_additional_id', additional.id)
        additionalsFormik.setFieldValue('paket_additional_name', additional.name)
        additionalsFormik.setFieldValue('paket_additional_price', additional.price)

        setTimeout(() => {
            additionalsFormik.setFieldTouched('paket_additional_id', 1)
        })

        closeDropdown()
    }

    const handleRemoveAdditional = (index) => {
        let dataJamaah = additionalsFormik.values.list_jamaah

        dataJamaah.splice(index, 1)

        additionalsFormik.setFieldValue('list_jamaah', dataJamaah)

    }

    const handleAddAdditional = async () => {
        setIsLoading(true)
        const response = await handleAddAdditionalAPI(params.orderNumber, additionalsFormik.values)
        setIsLoading(false)
        const { data, status } = response

        if(status === 200) {
            dispatch(handleSuccess(response))
            handleAddSuccess()
            dispatch(handleGetDetailOrder(params.orderNumber))
            dispatch(handleGetDataJamaah(params.orderNumber))
            setTimeout(() => {
                additionalsFormik.resetForm()
            }, 500)
        } else {
            dispatch(handleError(data))
        }
        
    }
    
    const handleAddJamaah = () => {
        setAdditionalSelectedIndex(additionalsFormik.values.list_jamaah.length)

        const newAdditional = {
            order_kamar_mapping_id: null,
            quantity: 1,
            note: null
        }

        let currentJamaah = additionalsFormik.values.list_jamaah
        currentJamaah.push(newAdditional)

        additionalsFormik.setFieldValue('list_jamaah', currentJamaah)

        setTimeout(() => {
            additionalsFormik.setFieldTouched('list_jamaah', 1)
        })

        setIsAdditionalSelected(true)
        
    }

    useEffect(() => {
        handleGetAdditionalPackage()
    }, [packageID])

    useEffect(() => {
        
        if(!additionalsFormik.values.list_jamaah.length) {
            handleAddJamaah()
        }

    }, [])

    useEffect(() => {

    }, [])
    

    return (
        <>
            <div className="border rounded-t-2xl p-4">
                <Scroll 
                    maxHeight="390px"
                    >
                    
                    <div className="p-4 border rounded-2xl ">
                        <div className="items-center flex gap-2">
                            <Dropdowns
                                dropdownArrow={true}
                                customClass="flex items-center gap-2"
                                labelDropdown={
                                    <>
                                        <div className="flex items-center">
                                            {additionalLabel}
                                        </div>
                                    </>
                                }
                                >
                                <div className="rounded-2xl shadow-custom-1 overflow-hidden w-[300px]">
                                    <div className="bg-orange-1 font-semibold text-white text-base p-4">
                                        Select Additional
                                    </div>
                                    <div className="bg-white text-sm">
                                        <Scroll 
                                            height="350px"
                                        >

                                            {additionals.filter(item => item?.is_wajib !== '1').length ? additionals.filter(item => item?.is_wajib !== '1').map(additional => (
                                                <>
                                                    <div
                                                        onClick={() => handleAdditionalSelecting(additional)}
                                                        className={`
                                                            p-4 mb-1 cursor-pointer hover:bg-[#FBF3F2]
                                                            ${additionalSelected && additional?.id_additional === additionalSelected?.id_additional  && (
                                                                `bg-[#FBF3F2]`
                                                            )}
                                                        `}>
                                                        <div className="flex items-center gap-2">
                                                            <div>
                                                                {additional?.name}
                                                            </div>
                                                            {additionalSelected && additional.id_additional === additionalSelected.id_additional  && (
                                                                <div>
                                                                    <img 
                                                                        src={IconChecklisted}
                                                                        />
                                                                </div>
                                                            )}
                                                        </div>
                                                        <div>
                                                            <div className="orange-linear-text font-semibold">
                                                                {IDRFormater(additional.price)}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                            )) : (
                                                <>
                                                    <div className="h-full w-full flex justify-center text-center items-center">
                                                        Tidak tersedia additional dipaket yang anda order
                                                    </div>
                                                </>
                                            )}

                                        </Scroll>
                                    </div>
                                </div>
                            </Dropdowns>
                        </div>
                        <div className="mt-3 bg-orange-1 text-white rounded-2xl p-4 text-xs">
                            <div className="mb-2">
                                Harga
                            </div>
                            <div className="font-semibold">
                                {additionalSelected ? (
                                    <>
                                        {IDRFormater(additionalSelected.price)}
                                    </>
                                ) : (
                                    <>
                                        -
                                    </>
                                )}
                            </div>
                        </div>
                    </div>

                    {additionalsFormik.values.paket_additional_id && (
                        <>
                            <div className="mt-4 bg-[#FBF3F2] p-4 rounded-2xl">
                                <div className="flex justify-between items-center">
                                    <h3 className="m-0 font-semibold text-sm">Jamaah</h3>
                                    <Buttons
                                        text="Tambah Jamaah"
                                        customClass="btn-primary btn-small"
                                        disabled={isAdditionalSelected}
                                        onClick={handleAddJamaah}
                                        />
                                </div>

                                <div className="mt-4">
                                    {!!additionalsFormik.values.list_jamaah.length && additionalsFormik.values.list_jamaah.map((additional, index) => (
                                        <>
                                            
                                            {isAdditionalSelected && additionalSelectedIndex === index ? (
                                                <AdditionalJamaahForm 
                                                    order={order}
                                                    additionalsFormik={additionalsFormik}
                                                    index={index}
                                                    handleSave={() => setIsAdditionalSelected(false)}
                                                    />
                                            ) : (
                                                <AdditionalJamaahCard
                                                    order={order}
                                                    additionalsFormik={additionalsFormik}
                                                    index={index}
                                                    handleRemove={handleRemoveAdditional}
                                                    handleEdit={() => {
                                                        setAdditionalSelectedIndex(index)
                                                        setIsAdditionalSelected(true)
                                                    }}
                                                    />
                                            )}
                                        </>
                                    ))}
                                </div>

                            </div>
                        </>
                    )}

                </Scroll>
            </div>
            <div className="border border-t-0 rounded-b-2xl p-4 flex justify-end">
                <Buttons
                    text="Tambah Additional"
                    customClass="btn-primary btn-large"
                    disabled={additionalsFormik.dirty && !additionalsFormik.isValid}
                    loading={isLoading}
                    onClick={handleAddAdditional}
                    />
            </div>
        </>
    )
}

export default AddAdditional