import React, { useEffect, useState } from "react";
import MoslemeTable from "../../../../components/Table";
import { useDispatch, useSelector }  from "react-redux";
import { useParams } from "react-router-dom";

import { Edit, Trash } from "iconsax-react";

import { handleGetDataAdditionalsAPI } from "utils/api/booking";
import { handleError } from "stores/actions/errorGeneral";
import { render } from "@testing-library/react";
import { IDRFormater } from "utils/helpers";

const AdditionalTable = ({ additionals, isLoading, handleEdit, handleDelete }) => {
  useEffect(() => {
  }, [additionals])

  const { bookingDetail } = useSelector(state => state.booking)

  const columns = [
    {
      title: "No",
      width: "50px",
      align: "center",
      render: (record, rec, index) => (
        <>
          {index+1}
        </>
      )
    },
    {
      title: "Nama Additional",
      width: '200px',
      render: (record) => (
        <>
          {record?.name}
        </>
      )
    },
    {
      title: "Jumlah",
      dataIndex: "quantity",
      align: 'center'
    },
    {
      title: "Catatan",
      dataIndex: "note",
      render: (note) => (
        <>
          {note ? note : "-"}
        </>
      )
    },
    {
      title: "Harga",
      dataIndex: "price",
      render: (price) => (
        <>
          {IDRFormater(price)}
        </>
      )
    },
    {
      title: "Harga Total",
      dataIndex: "price",
      render: (price, record) => (
        <>
          <div>
            {IDRFormater(price * record.quantity)}
          </div>
          {bookingDetail?.payment_status !== 4 && (
            <div className="action">
              <div className="action-content">
                <div className="action-content-item">
                  <Edit
                    className="cursor-pointer"
                    onClick={() => handleEdit(record)}
                    size="15"
                    color="#141414"
                  />
                </div>
                
                {record?.is_wajib != 1 && (
                  <div className="action-content-item">
                    <Trash
                      className="cursor-pointer"
                      onClick={() => handleDelete(record)}
                      size="15"
                      color="#141414"
                    />
                  </div>
                )}
              </div>
            </div>
          )}
        </>
      )
    },
  ];

  return (

    <MoslemeTable
      columns={columns}
      data={additionals}
      withSelection={false}
      selectionType={false}
      withPagination={false}
      loading={isLoading}
    />
  );
};

export default React.memo(AdditionalTable);
